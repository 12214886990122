<div class="row">
    <div class="col-lg-7">
        <cms-template-section id="20dadf34-60b0-4d37-a986-ef564577c264"></cms-template-section>	
    </div>
    <div class="col-lg-5">
        <div class="card mb-3">
            <div class="card-body">
                <cms-template-section id="9d21c0db-81af-432e-b65e-10886013446d"></cms-template-section>	
            </div>
        </div>
        
    </div>
</div>