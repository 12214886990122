import { Component, Inject } from '@angular/core';
import { MENU_MODEL } from '@murdoughsolutions/cms-client';
import { MenuModel, WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdoughsolutions/cms-common';

@Component({
  selector: 'CustomMainMenuDesktop',
  templateUrl: './main-menu-desktop.html',
  styleUrls: ['./main-menu-desktop.scss']
})
export class CustomMainMenuDesktop {

  constructor(
    @Inject(MENU_MODEL) public readonly menu: MenuModel,
    @Inject(WEBSITE_DOMAIN) public readonly domain: WebsiteDomainModel,
  ) {

  }

}
