import { Component } from '@angular/core';
import { MetaService } from './services/meta.service';
import { component_resolver } from './custom-components/component-map';
import { Angulartics2GoogleTagManager } from 'angulartics2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public readonly component_resolver = component_resolver

  constructor(
    private readonly meta: MetaService,
    private readonly gtm: Angulartics2GoogleTagManager
  ) {
    this.meta.Initialize()
    this.gtm.startTracking()
  }
}
