import { CustomEMailInput } from './e-mail-input/e-mail-input';
import { CustomGalleryModal } from './gallery-modal/gallery-modal';
import { CustomGallery } from './gallery/gallery';
import { CustomHomePage } from './home-page/home-page';
import { CustomHoneypot } from './honeypot/honeypot';
import { CustomMainMenuDesktop } from './main-menu-desktop/main-menu-desktop';
import { CustomMainMenuMobile } from './main-menu-mobile/main-menu-mobile';
import { CustomMiniCarousel } from './mini-carousel/mini-carousel';
import { CustomPage } from './page/page';
import { CustomPlaceholderForm } from './placeholder-form/placeholder-form';
import { CustomPricingPage } from './pricing-page/pricing-page';
import { CustomTestimonialsCarousel } from './testimonials-carousel/testimonials-carousel';
import { CustomTextInput } from './text-input/text-input';
import { CustomTextareaInput } from './textarea-input/textarea-input';

import { Type } from '@angular/core';

export function component_resolver(template_id: string): Type<any> | undefined {
    switch (template_id) {
        case '519da055-055d-4d65-a8ed-741e7fefb630': return CustomMainMenuMobile
        case 'ae17265b-ba83-4cb8-99d7-d18389fc303f': return CustomTextInput
        case 'f58c26df-567b-4df8-987d-f8fb2cbdda82': return CustomMiniCarousel
        case 'a4767ee2-b3c4-456b-b51b-22943147225f': return CustomTextareaInput
        case '27f6b925-ada1-498c-8b6a-0accd407c301': return CustomPricingPage
        case 'b2389d57-1c6f-406f-a0e4-61c68a18d7d4': return CustomGalleryModal
        case 'f08a808f-7a9a-4012-8d5d-4068b4676cb1': return CustomHomePage
        case '3cc71593-46c3-4c28-8ce7-2e2ed9224708': return CustomEMailInput
        case 'd6f52d6d-b496-4e55-b0d9-1023675ed57a': return CustomHoneypot
        case '99c5196a-ae66-4ffc-b534-a8cc7c2e682d': return CustomMainMenuDesktop
        case '23ca5628-c7d1-4c25-bbe5-fa9316acd002': return CustomPlaceholderForm
        case '5a1cf716-9c63-42e9-aab5-48307d7b4da7': return CustomGallery
        case '67f83cb2-f2d7-4de2-aedb-cadcd30e89f6': return CustomTestimonialsCarousel
        case '8b530f11-924b-4cda-ba95-e72e050f4b07': return CustomPage
    }
    return undefined
}