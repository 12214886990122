import { Component } from '@angular/core';
import { CDNService, MediaItemModel } from '@murdoughsolutions/cms-common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'CustomGalleryModal',
  templateUrl: './gallery-modal.html',
  styleUrls: ['./gallery-modal.scss']
})
export class CustomGalleryModal {
  public media_item?: MediaItemModel;

  constructor(public modal: NgbActiveModal, public cdn: CDNService) {

  }

}
