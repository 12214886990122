import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { NgPipesModule } from 'ngx-pipes';

import { FormService } from './services/form.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { MetaService } from './services/meta.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentResolver } from './resolvers/content.resolver';
import { PostCategoryResolver } from './resolvers/post-category.resolver';
import { PostCollectionResolver } from './resolvers/post-collection.resolver';
import { PostResolver } from './resolvers/post.resolver';

import { CmsFormFieldComponent } from './components/cms-form-field/cms-form-field.component';
import { CmsFormComponent } from './components/cms-form/cms-form.component';
import { CmsLoadingOverlayComponent } from './components/cms-loading-overlay/cms-loading-overlay.component';

import { CmsClientModule } from '@murdoughsolutions/cms-client';
import { CmsCommonModule } from '@murdoughsolutions/cms-common';
import { environment } from 'src/environments/environment';
import { CustomEMailInput } from './custom-components/e-mail-input/e-mail-input';
import { CustomGalleryModal } from './custom-components/gallery-modal/gallery-modal';
import { CustomGallery } from './custom-components/gallery/gallery';
import { CustomHomePage } from './custom-components/home-page/home-page';
import { CustomHoneypot } from './custom-components/honeypot/honeypot';
import { CustomMainMenuDesktop } from './custom-components/main-menu-desktop/main-menu-desktop';
import { CustomMainMenuMobile } from './custom-components/main-menu-mobile/main-menu-mobile';
import { CustomMiniCarousel } from './custom-components/mini-carousel/mini-carousel';
import { CustomPage } from './custom-components/page/page';
import { CustomPlaceholderForm } from './custom-components/placeholder-form/placeholder-form';
import { CustomPricingPage } from './custom-components/pricing-page/pricing-page';
import { CustomTestimonialsCarousel } from './custom-components/testimonials-carousel/testimonials-carousel';
import { CustomTextInput } from './custom-components/text-input/text-input';
import { CustomTextareaInput } from './custom-components/textarea-input/textarea-input';

const isPrerender = window.navigator.userAgent?.includes('Murdough Solutions CMS')

@NgModule({
  declarations: [
    AppComponent,
    CmsLoadingOverlayComponent,
    CmsFormComponent,
    CmsFormFieldComponent,
    CustomMainMenuMobile, CustomTextInput, CustomMiniCarousel, CustomTextareaInput, CustomPricingPage, CustomGalleryModal, CustomHomePage, CustomEMailInput, CustomHoneypot, CustomMainMenuDesktop, CustomPlaceholderForm, CustomGallery, CustomTestimonialsCarousel, CustomPage
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgbModule,
    FontAwesomeModule,
    Angulartics2Module.forRoot({
      developerMode: !environment.production || isPrerender
    }),
    CmsCommonModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base,
      cdn_base: environment.cdn_base,
      default_date_format: 'PPP',
      default_date_time_format: 'PPP, p'
    }),
    CmsClientModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base
    }),
    AppRoutingModule,
  ],
  providers: [
    GoogleTagManagerService,
    MetaService,
    FormService,
    ContentResolver,
    PostCollectionResolver,
    PostCategoryResolver,
    PostResolver,
    { 
      provide: NgbDateAdapter, 
      useClass: NgbDateNativeAdapter
    }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

}
