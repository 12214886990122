<ngb-carousel class="carousel-wrapper" *ngIf="post_collection" [wrap]="true" [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="20000">
    <ng-template ngbSlide *ngFor="let post of post_collection.posts | shuffle">
        <div class="testimonial-wrapper">
            <div class="card">
                <div class="card-body">
                    <p><img src="/cdn/assets/098fc72e-d627-4f80-b153-cd5cc79d4be3.png" /></p>
                    <cms-template-section id="391bb7a0-a7bf-41d3-95af-88d4d40f120d" [post]="post"></cms-template-section>	
                    <p class="text-right"><img src="/cdn/assets/7f7d2a2e-0a89-404f-a772-764ec3f87826.png" /></p>
                </div>
            </div>
            <div class="card-by">
                <p class="font-weight-bold">{{ post.name }}</p>
            </div>
        </div>
    </ng-template>
</ngb-carousel>