<cms-template-section id="b5daeda6-c4ed-4bed-ab88-b88901ecc3c1"></cms-template-section>	

<div class="row mb-3">
    <div class="col-lg-4">
        <cms-media-collection id="9a161a92-3d94-4850-98cd-eda2dca45199" [component_resolver]="component_resolver"></cms-media-collection>
    </div>
    <div class="col-lg-8">
        <cms-template-section id="1a5f4c00-5d4e-4940-a93e-edf84dc737d7"></cms-template-section>	
    </div>
</div>
<div class="row" *ngIf="content$ | async as content">
    <div class="col-lg-6">
        <div class="card mb-3">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6">
                        <cms-template-section id="8cfb212a-31aa-48d5-ac0f-9f9c8d87f4fc"></cms-template-section>	
                    </div>
                    <div class="col-lg-6">
                        <cms-form id="c3523ce7-e42b-4363-a422-e8715b673d8e"></cms-form>
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="col-lg-3">
        <a *ngIf="content.properties['gallery_page']?.page as gallery_page" [routerLink]="gallery_page.route" id="gallery-link">
            <img src="/cdn/assets/0952f2a9-9ba1-4035-94be-e46126e30f18.jpg" class="img-fluid d-block mx-auto" />
        </a>
    </div>
    <div class="col-lg-3">
        <a *ngIf="content.properties['processing_page']?.page as processing_page" [routerLink]="processing_page.route" id="processing-link">
            <img src="/cdn/assets/f2867247-d15c-4ceb-8d95-4b9c6348c059.jpg" class="img-fluid d-block mx-auto" />
        </a>
    </div>
</div>