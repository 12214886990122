import { Component, Inject } from '@angular/core';
import { POST_COLLECTION_MODEL } from '@murdoughsolutions/cms-client';
import { CDNService, PostCollectionModel } from '@murdoughsolutions/cms-common';

@Component({
  selector: 'CustomTestimonialsCarousel',
  templateUrl: './testimonials-carousel.html',
  styleUrls: ['./testimonials-carousel.scss']
})
export class CustomTestimonialsCarousel {
    
  constructor(
    @Inject(POST_COLLECTION_MODEL) public readonly post_collection: PostCollectionModel,
      public readonly cdn: CDNService
  ) {
      
  }

}
