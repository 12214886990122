<div class="row" *ngIf="content$ | async as content">
    <div class="col-lg-8">
        <cms-template-section id="008a939f-cced-400b-8ce0-c87378039285"></cms-template-section>	

        <div *ngIf="content.properties['gallery_collection']?.media_collection as media_collection">
            <cms-media-collection [id]="media_collection.media_collection_id" [component_resolver]="component_resolver"></cms-media-collection>
        </div>

    </div>
    <div class="col-lg-4">
        <div class="card mb-3">
            <div class="card-body">
                <h4>CONTACT US</h4>
                <p>For any questions about our Taxidermy or Processing Services, please feel free to use this form or call us at 254-848-4401 or 254-717-2438. We look forward to hearing from you!</p>
                <cms-form id="c3523ce7-e42b-4363-a422-e8715b673d8e"></cms-form>
            </div>
        </div>
        
        <ng-template #defaultTestimonials>
            <cms-post-collection id="6319ff05-e025-4e16-896b-f9f9ffca9cd8" [component_resolver]="component_resolver"></cms-post-collection>
        </ng-template>
        <cms-post-collection *ngIf="content.properties['testimonials_collection']?.post_collection as testimonials_collection; else defaultTestimonials" [id]="testimonials_collection.post_collection_id" [component_resolver]="component_resolver"></cms-post-collection>

    </div>
</div>