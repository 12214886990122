import { Inject, NgModule } from '@angular/core';
import { Route, Router, RouterModule, Routes } from '@angular/router';
import { WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdoughsolutions/cms-common';
import { component_resolver } from './custom-components/component-map';
import { ContentResolver } from './resolvers/content.resolver';
import { PostCategoryResolver } from './resolvers/post-category.resolver';
import { PostCollectionResolver } from './resolvers/post-collection.resolver';
import { PostResolver } from './resolvers/post.resolver';

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(
    private readonly router: Router, 
    @Inject(WEBSITE_DOMAIN) private readonly domain: WebsiteDomainModel
  ) {
    this.router.resetConfig(this.build_routes());
  }

  private build_route(route: Array<string>): string {
    let str = route.join('/');
    if (str.startsWith('//')) {
      str = str.substring(2);
    }
    else if (str.startsWith('/')) {
      str = str.substring(1);
    }
    return str;
  }


  public build_routes(): Routes {
    const routes: Routes = [];

    if (this.domain.website.pages) {
      for (const page of this.domain.website.pages) {
        const component = component_resolver(page.template_id)
        if (component) {
          const route: Route = {
            path: this.build_route(page.route),
            component,
            data: {
              page_model: page,
              content_id: page.content_id
            },
            resolve: {
              content_model: ContentResolver
            }
          };
          routes.push(route);
        }
      }
    }

    // posts
    if (this.domain.website.post_collections) {
      for (const post_collection of this.domain.website.post_collections) {
        const collection_component = component_resolver(post_collection.template_id)
        if (collection_component) {
          const route: Route = {
            path: this.build_route(post_collection.route),
            component: collection_component,
            data: {
              post_collection_id: post_collection.post_collection_id,
              content_id: post_collection.content_id
            },
            resolve: {
              post_collection_model: PostCollectionResolver,
              content_model: ContentResolver
            }
          };
          routes.push(route);
        }

        if (post_collection.categories) {
          for (const category of post_collection.categories) {
            const category_component = component_resolver(category.template_id)
            if (category_component) {
              const route: Route = {
                path: this.build_route(category.route),
                component: category_component,
                data: {
                  post_collection_id: post_collection.post_collection_id,
                  post_category_id: category.post_category_id,
                  content_id: category.content_id
                },
                resolve: {
                  post_collection_model: PostCollectionResolver,
                  post_category_model: PostCategoryResolver,
                  content_model: ContentResolver
                }
              };
              routes.push(route);
            }
          }
        }

        if (post_collection.posts) {
          for (const post of post_collection.posts) {
            const post_component = component_resolver(post.template_id)
            if (post_component) {
              const route: Route = {
                path: this.build_route(post.route),
                component: post_component,
                data: {
                  post_collection_id: post_collection.post_collection_id,
                  post_id: post.post_id,
                  content_id: post.content_id
                },
                resolve: {
                  post_collection_model: PostCollectionResolver,
                  post_model: PostResolver,
                  content_model: ContentResolver
                }
              };
              routes.push(route);
            }
          }
        }
      }
    }

    routes.push({
      path: '**',
      redirectTo: ''
    });
    return routes;
  }
}
