import { Injectable } from '@angular/core';
import { FormCompletionModel } from '@murdoughsolutions/cms-common';
import { Angulartics2GoogleTagManager } from 'angulartics2';

@Injectable()
export class GoogleTagManagerService {

    constructor(
        private readonly gtm: Angulartics2GoogleTagManager
    ) {
        
    }


    private pushLayer(layer: any): void {
        this.gtm.pushLayer({ ecommerce: null, form: null });
        this.gtm.pushLayer(layer);
    }


    public FormCompletion(completion: FormCompletionModel): void {
        if (!completion.conversion_event_name) {
            return;
        }
        const tag = {
            event: completion.conversion_event_name,
            form: {
                name: completion.name
            }
        };

        this.pushLayer(tag);
    }

}
