<cms-loading-overlay></cms-loading-overlay>
<section id="main-wrapper">
    <div id="top-bar" class="d-none d-lg-block">
        <div class="container pt-1 pb-1">
            Call Today: <a href="tel:2548484401">254-848-4401</a>
        </div>
    </div>
    <div id="header-desktop" class="d-none d-lg-block">
        <a class="logo" [routerLink]="['/']"></a>
        <div class="main-menu">
        	<cms-menu id="0201b7e3-8de2-4cac-86c4-9927288239f2" [component_resolver]="component_resolver"></cms-menu>
       </div>
    </div>
    <div id="header-mobile" class="container d-lg-none pb-3">
        <a class="logo mb-3" [routerLink]="['/']"></a>
    </div>
    <div id="menu-mobile" class="container d-lg-none pt-3 pb-3">
        <h2 class="text-center"><a href="tel:2548484401">254-848-4401</a></h2>
        <div class="main-menu">
        	<cms-menu id="c15ad461-a9c5-4689-aa01-3676f97c1b6b" [component_resolver]="component_resolver"></cms-menu>
       </div>
    </div>
    <div id="content" class="container pt-3 pb-3 pt-lg-4">
        <router-outlet></router-outlet>
    </div>
    <div id="footer">
        <div class="container pt-1 pb-1">
            All Rights Reserved | Texas Country Critters | Montgomery Processing | 155 Antler Road, Waco, TX 76712
            <span class="d-lg-none"> | </span>
            <span class="float-lg-right">254-848-4401</span>
        </div>
    </div>
</section>