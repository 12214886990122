import { Component, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomGalleryModal } from '../gallery-modal/gallery-modal';
import { CDNService, MediaCollectionModel, MediaItemModel } from '@murdoughsolutions/cms-common';
import { MEDIA_COLLECTION_MODEL } from '@murdoughsolutions/cms-client';

@Component({
  selector: 'CustomGallery',
  templateUrl: './gallery.html',
  styleUrls: ['./gallery.scss']
})
export class CustomGallery {

  constructor(
    @Inject(MEDIA_COLLECTION_MODEL) public readonly media_collection: MediaCollectionModel,
    private readonly modalService: NgbModal,
    public readonly cdn: CDNService
  ) {

  }

  openModal(media_item: MediaItemModel) {
    const modal = this.modalService.open(CustomGalleryModal, { size: 'lg' });
    modal.componentInstance.media_item = media_item;

    modal.result.then((result) => {

    }, (reason) => {

    });
  }
}
