import { Component, Inject, OnInit } from '@angular/core';
import { MEDIA_COLLECTION_MODEL } from '@murdoughsolutions/cms-client';
import { CDNService, MediaCollectionModel } from '@murdoughsolutions/cms-common';

@Component({
  selector: 'CustomMiniCarousel',
  templateUrl: './mini-carousel.html',
  styleUrls: ['./mini-carousel.scss']
})
export class CustomMiniCarousel {

  constructor(
    @Inject(MEDIA_COLLECTION_MODEL) public readonly media_collection: MediaCollectionModel,
    public readonly cdn: CDNService
  ) {

  }

}
