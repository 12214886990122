import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FORM_COMPLETION_MODEL } from '@murdoughsolutions/cms-client';
import { FormCompletionFieldModel, FormCompletionModel } from '@murdoughsolutions/cms-common';
import { take } from 'rxjs/operators';
import { FormBroker } from 'src/app/brokers/form.broker';
import { CmsFormParameters } from 'src/app/components/cms-form/cms-form.component';
import { FormState } from 'src/app/enum/form-state.enum';
import { ActionFeedbackModel } from 'src/app/models/action-feedback.model';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'CustomPlaceholderForm',
  templateUrl: './placeholder-form.html',
  styleUrls: ['./placeholder-form.scss']
})
export class CustomPlaceholderForm {
  public readonly fields: Array<FormCompletionFieldModel>;
  public readonly form_group: FormGroup;
  public form_completion?: FormCompletionModel;
  public state?: FormState;
  public error_message?: string;

  public FormState = FormState;

  constructor(
    private readonly router: Router,
    @Inject(FORM_COMPLETION_MODEL) public readonly form: FormCompletionModel,
    public readonly form_service: FormService,
    public readonly broker: FormBroker,
    public readonly options: CmsFormParameters
  ) {
    this.fields = this.form.fields;
    this.form_group = this.options.form_group;

    if (this.broker.feedbackHandler) {
      this.broker.feedbackHandler.subscribe((feedback: ActionFeedbackModel) => {
        if (feedback.form_id == this.form.form_id) {
          this.state = feedback.state;
          this.error_message = feedback.error;
        }
      });
    }
  }

  submit() {
    this.state = FormState.None;
    if (!this.form_service.Validate(this.form_group, this.form)) {
      this.state = FormState.Invalid;
      return;
    }

    if (this.broker.submitHandler?.observers.length > 0) {
      this.broker.submitHandler.emit(this.form);
    }
    else {
      this.form_service.CompleteForm(this.form.form_id, this.form).pipe(
        take(1)
      ).subscribe(
        data => {
          this.form_completion = data;

          if (this.form.complete_page) {
            this.router.navigate(this.form.complete_page.route);
          }
          this.state = FormState.Success;
        },
        error => {
          this.error_message = error;
          this.state = FormState.Error;
        });
    }
  }
}
